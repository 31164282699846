import 'patient/styles/medic-profile.css';
import React, { Fragment, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ListGroup from 'react-bootstrap/ListGroup';
import { Modal } from 'react-bootstrap';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import {
  getAuthState,
  getDoctorProfileState,
  getFavouriteDoctorsState,
  getPatientAppointmentState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import {
  fetchDoctorProfileRequest,
  fetchDoctorProfileSuccess,
} from 'shared/redux/actions/DoctorProfileActions';
import {
  addFavouriteDoctorRequest,
  removeFavouriteDoctorRequest,
  getFavouriteDoctorsRequest,
} from 'shared/redux/actions/FavouriteDoctorsActions';
import PatientMedicReviewsModal from 'patient/components/PatientMedicReviewsModal';
import PatientMedicCardButton from 'patient/screens/medicList/PatientMedicCardButton';
import { translatedDayOfWeekWithNumber } from 'shared/modules/DateTimeUtils';
import {
  allowNewAppointment,
  isAnyAppointmentInProgress,
} from 'shared/modules/PatientAppointmentUtils';
import {
  appointmentLocalStepsRequest,
  setPendingAppointment,
} from 'shared/redux/actions/PatientAppointmentActions';
import openGraphImage from 'shared/assets/images/ringdoc_image_open_graph.png';
import noReviewsImage from 'patient/assets/images/no-reviews-available.svg';
import Alerts from 'shared/components/Alerts';
import ShareOnSocial from 'patient/components/ShareOnSocial';
import Utils from 'shared/modules/Utils';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import _ from 'lodash';
import * as DOMPurify from 'dompurify';
import { isMobile } from 'react-device-detect';
import GAService from '../../../shared/services/GAService';
import { requestMediaCamera } from '../../../shared/modules/PermissionsUtils';
import SkeletonComponent from '../../../shared/components/SkeletonComponent';
import { createLoadingSelector } from '../../../shared/redux/src/GenericSeletors';

const PatientMedicProfile = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { doctorIdentifier } = useParams();

  // in functie de specializare setam canonical
  const canonicalPath = `https://ringdoc.ro${location.pathname}`;

  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const loadingSelector = createLoadingSelector();
  const isLoading = useSelector(loadingSelector);

  const lastParam = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const id = lastParam.split('-')[lastParam.split('-').length - 1];

  const [doctor, setDoctor] = useState({
    fullNameWithTitle: '',
    firstName: '',
    lastName: '',
    doctorSpecializations: [],
    organization: {
      clinicName: '',
    },
    address: '',
    //
    id: '',
    name: '',
    doctorDescription: '',
    imgUrl: '',
    isAvailableForCallNow: '',
    meta: {},
    availability: '',
    averageRating: '',
    countRating: '',
    hasSchedule: '',
    cvMediaUrl: '',
    timeTable: [],
    reviews: [],
    pictureMedia: '',
  });
  const doctor2 = useSelector(getDoctorProfileState, shallowEqual);
  const { ids: favouriteDoctorsIds } = useSelector(getFavouriteDoctorsState, shallowEqual);
  const [isFavorite, setFavorite] = useState<boolean | undefined>(undefined);
  const [description, setDescription] = useState(Utils.shortenText(doctor.doctorDescription, 120));
  const [isFullDescriptionShown, setIsFullDescriptionShown] = useState<boolean>(false);
  const [hasProfilePicture, setHasProfilePicture] = useState<boolean>(false);
  const userProfile = useSelector(getUserProfileState, shallowEqual);
  const showFullDescription = () => {
    setDescription(doctor.doctorDescription);
    setIsFullDescriptionShown(true);
  };

  const sanitizer = DOMPurify.sanitize;

  useEffect(() => {
    setDoctor(doctor2);
  }, [doctor2]);
  useEffect(() => {
    setDoctor({
      fullNameWithTitle: '',
      firstName: '',
      lastName: '',
      doctorSpecializations: [],
      organization: {
        clinicName: '',
      },
      address: '',
      //
      id: '',
      name: '',
      doctorDescription: '',
      imgUrl: '',
      isAvailableForCallNow: '',
      meta: {},
      availability: '',
      averageRating: '',
      countRating: '',
      hasSchedule: '',
      cvMediaUrl: '',
      timeTable: [],
      reviews: [],
      pictureMedia: '',
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getFavouriteDoctorsRequest({ limit: 500 }));
    }
    // dispatch(fetchDoctorProfileRequest({ id: doctorIdentifier }));
    dispatch(fetchDoctorProfileRequest({ id }));
  }, [i18next.language]);

  useEffect(() => {
    if (id !== undefined) {
      setFavorite(favouriteDoctorsIds.indexOf(parseInt(id, 10)) > -1);
    }
  }, [favouriteDoctorsIds, i18next.language]);

  useEffect(() => {
    if (doctor.doctorDescription?.length > 120) {
      setDescription(Utils.shortenText(doctor.doctorDescription, 120));
      setIsFullDescriptionShown(false);
    } else {
      setDescription(doctor.doctorDescription);
      setIsFullDescriptionShown(true);
    }
  }, [doctor, i18next.language]);

  const toggleFavorite = () => {
    if (isFavorite === undefined || id === undefined) {
      return;
    }

    if (isFavorite) {
      dispatch(removeFavouriteDoctorRequest(parseInt(id, 10)));
    } else {
      dispatch(addFavouriteDoctorRequest(parseInt(id, 10)));
    }
    setFavorite(!isFavorite);
  };

  const [showModalAllReviews, setModalAllReviewsShow] = useState(false);
  const modalAllReviewsClose = () => setModalAllReviewsShow(false);
  const modalAllReviewsShow = () => setModalAllReviewsShow(true);

  const [modalReadMoreReview, setModalReadMoreReview] = useState<
    Record<string, string> | undefined
  >(undefined);
  const [showModalReviewReadAll, setModalReviewReadAllShow] = useState(false);
  const modalReviewReadAllClose = () => setModalReviewReadAllShow(false);
  const modalReviewReadAllShow = (review) => {
    setModalReadMoreReview(review);
    setModalReviewReadAllShow(true);
  };
  const { status: appointmentStatus, stickyStatus } = useSelector(
    getPatientAppointmentState,
    shallowEqual,
  );

  const [showModalViewProfilePicture, setModalViewProfilePictureShow] = useState(false);
  const modalViewProfilePictureClose = () => setModalViewProfilePictureShow(false);
  const modalViewProfilePictureShow = () => {
    if (doctor?.imgUrl) {
      setModalViewProfilePictureShow(true);
    }
  };

  const onScheduleClicked = () => {
    if (!isLoggedIn) {
      dispatch(setPendingAppointment({ type: 'programmed', doctor, specializationId: false }));
      Alerts.okCancelAlert(t('info'), t('alerts.youNeedToLogin'), () => {
        localStorage.setItem('CURRENT_LINK', `${window.location.pathname}`);
        navigate('/patient/login');
      });
      return;
    }

    requestMediaCamera().then((response) => {
      if (!response) {
        window.location.href = '/support/permissions';
      } else {
        if (!allowNewAppointment(appointmentStatus, stickyStatus)) {
          return;
        }
        dispatch(
          appointmentLocalStepsRequest({
            newAppointment: true,
            noOfSteps: 5,
            type: 'programmed',
            doctor,
          }),
        );
        navigate('/patient/appointment/specialization');
      }
    });
  };

  const callPress = () => {
    if (!isLoggedIn) {
      dispatch(setPendingAppointment({ type: 'callNow', doctor, specializationId: false }));
      Alerts.okCancelAlert(t('info'), t('alerts.youNeedToLogin'), () => {
        localStorage.setItem('CURRENT_LINK', `${window.location.pathname}`);
        navigate('/patient/login');
      });
      return;
    }
    if (isAnyAppointmentInProgress(appointmentStatus, stickyStatus)) {
      Alerts.simpleAlert(t('info'), t('validations.alreadyHasAppointment'));
      return;
    }
    requestMediaCamera().then((response) => {
      if (!response) {
        window.location.href = '/support/permissions';
      } else if (doctor?.isAvailableForCallNow) {
        const specializationId = doctor.doctorSpecializations
          ?.map((specialization: any) => specialization.id)
          .join(', ');
        const specializationName = doctor.doctorSpecializations
          ?.map((specialization: any) => specialization.name)
          .join(', ');
        GAService.event('web_callNow', { specializationName, specializationId });
        dispatch(
          appointmentLocalStepsRequest({
            doctor,
            type: 'callNow',
            noOfSteps: 4,
            newAppointment: true,
          }),
        );
        navigate('/patient/appointment/specialization');
      }
    });
  };

  const currentUrl = window.location.href;
  const baseUrl = window.location.origin;

  const lang = i18next.language;

  const [metaTitleContent, setMetaTitleContent] = useState(doctor.fullNameWithTitle);
  const [metaDescriptionContent, setMetaDescriptionContent] = useState(
    Utils.shortenText(doctor.doctorDescription, 160),
  );

  const getFile = async (fileLink) => {
    if (fileLink && fileLink !== '') {
      const response = await fetch(fileLink);
      if (response.status === 200) {
        setHasProfilePicture(true);
      }
    }
  };

  useEffect(() => {
    if (doctor.meta) {
      if (lang in doctor.meta) {
        if (doctor.meta[lang].metaTitle?.length > 0) {
          setMetaTitleContent(doctor.meta[lang].metaTitle);
        }
        if (doctor.meta[lang].metaDescription?.length > 0) {
          setMetaDescriptionContent(Utils.shortenText(doctor.meta[lang].metaDescription, 160));
        }
      }
    }

    getFile(doctor?.imgUrl).then();
  }, [doctor]);

  let mainSpecialization = '';
  doctor.doctorSpecializations?.forEach((spec, index) => {
    if (index === 0) {
      // @ts-ignore
      mainSpecialization = spec.name;
    }
  });

  return (
    <section className={`medic-profile ${isMobile ? 'mobile' : ''}`}>
      <Helmet>
        <title>
          {`${doctor.fullNameWithTitle} ${doctor.doctorSpecializations
            ?.map((specialization: any) => specialization.name)
            .join(', ')} | Ringdoc`}
        </title>
        <meta name="description" content={Utils.shortenText(doctor.doctorDescription, 160)} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:locale" content="ro_RO" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:type:profile:username " content={doctor.fullNameWithTitle} />
        <meta property="og:title" content={metaTitleContent} />
        <meta property="og:description" content={metaDescriptionContent} />

        <meta
          property="og:image"
          content={doctor?.imgUrl ? doctor?.imgUrl : `${baseUrl}${openGraphImage}`}
        />
        <link rel="canonical" href={canonicalPath} />
      </Helmet>

      {/* Breadcrumbs */}
      <div className="breadcrumb">
        <div className="breadcrumb-item">
          <Link to="/">{t('breadcrumbs.homeBreadcrumbs')}</Link>
        </div>
        <div className="breadcrumb-item">
          <Link to="/patient/medicList">{t('breadcrumbs.doctors')}</Link>
        </div>
        <div className="breadcrumb-item">
          <Link to={`/patient/medicList/${mainSpecialization.toLowerCase()}`}>
            {mainSpecialization}
          </Link>
        </div>
        <div className="breadcrumb-item">{doctor.fullNameWithTitle}</div>
      </div>
      {/* END Breadcrumbs */}

      <div className="top-buttons">
        {isLoggedIn && (
          <button
            type="button"
            className={isFavorite ? 'favorite active' : 'favorite'}
            onClick={toggleFavorite}
          >
            <CustomIcon
              className="custom-icon"
              color={isFavorite ? Colors.patientColor : Colors.darkGrey}
              size="24"
              icon={isFavorite ? 'Heart_Filled' : 'Heart_Empty'}
            />
            {!isMobile && (
              <div className="text">
                {isFavorite ? t('medicalApp.savedAsFavorite') : t('medicalApp.saveFavorite')}
              </div>
            )}
          </button>
        )}
        {doctor.fullNameWithTitle !== '' && <ShareOnSocial doctor={doctor} profilePage />}
      </div>

      {isLoading && !doctor.name ? (
        <div className="medic-box">
          <div className="profile-picture">
            <SkeletonComponent shape="circle" width="108" height="108" />
          </div>
          <div className="details">
            <div className="rating">
              <SkeletonComponent height="14" width="130" />
            </div>
            <div className="name">
              <SkeletonComponent width="220" />
            </div>
            <div className="specialization">
              <SkeletonComponent width="220" />
            </div>
            <div className="clinic">
              <SkeletonComponent width="220" />
            </div>
          </div>
        </div>
      ) : (
        <div className="medic-box">
          <div className="profile-picture">
            <div
              className="avatar cursor-pointer"
              style={
                hasProfilePicture
                  ? { backgroundImage: `url(${doctor?.imgUrl})` }
                  : { backgroundColor: '#E5E7E8' }
              }
              onClick={modalViewProfilePictureShow}
            >
              {!hasProfilePicture && (
                <div className="image-placeholder">{`${doctor?.firstName?.charAt(
                  0,
                )}${doctor?.lastName?.charAt(0)}`}</div>
              )}
              <i className={`status ${doctor.availability}`} />
            </div>
          </div>

          <div className="details">
            <div className="rating">
              <CustomIcon
                className="custom-icon"
                color={Colors.mediumGrey}
                size="14"
                icon="Rating_2"
              />
              {t('grade')} {doctor.averageRating} / 5 ({doctor.countRating} {t('noOfReviews')})
            </div>
            <div className="name">{doctor.fullNameWithTitle}</div>
            <div className="specialization">
              {doctor.doctorSpecializations
                ?.map((specialization: any) => specialization.name)
                .join(', ')}
            </div>
            <div className="clinic">{doctor.organization?.clinicName}</div>
          </div>

          <div className="action-buttons">
            {doctor.hasSchedule && (
              <PatientMedicCardButton id={doctor.id} onClickEvent={onScheduleClicked} />
            )}
            <PatientMedicCardButton status={doctor.availability} onClickEvent={callPress} />
          </div>
        </div>
      )}

      <div className="row rowp10">
        <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp10">
          {isLoading && !doctor.name ? (
            <div className="about">
              <div className="subtitle">
                <SkeletonComponent width="100" />
              </div>
              <div className="box">
                <div>
                  <SkeletonComponent width="full" />
                </div>
                <div>
                  <SkeletonComponent width="full" />
                </div>
                <div>
                  <SkeletonComponent width="full" />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="about">
                <div className="subtitle">{t('about')}</div>
                {description && (
                  <div className="box">
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{ __html: sanitizer(description) }}
                    />

                    {!isFullDescriptionShown && (
                      <button type="button" className="read-all" onClick={showFullDescription}>
                        {t('readAll')}
                      </button>
                    )}
                  </div>
                )}
                {doctor.cvMediaUrl && (
                  <Link to="curriculum-vitae" className="view-cv">
                    <CustomIcon
                      className="custom-icon document"
                      color={Colors.patientColor}
                      size="20"
                      icon="Document"
                    />
                    {t('medicalApp.viewCV')}
                    <CustomIcon
                      className="custom-icon arrow"
                      color={Colors.patientColor}
                      size="24"
                      icon="Right_pointer"
                    />
                  </Link>
                )}
              </div>
            </>
          )}

          {isLoading && !doctor.name ? (
            <div className="schedule">
              <div className="subtitle">
                <SkeletonComponent width="100" />
              </div>
              <div className="box">
                <div>
                  <SkeletonComponent width="full" />
                </div>
                <div>
                  <SkeletonComponent width="full" />
                </div>
                <div>
                  <SkeletonComponent width="full" />
                </div>
              </div>
            </div>
          ) : (
            <>
              {doctor.timeTable.length > 0 && (
                <div className="schedule">
                  <div className="subtitle">{t('medicalApp.schedule')}</div>
                  <div className="box">
                    <ListGroup>
                      {doctor.timeTable?.map((day: any) => {
                        return (
                          <ListGroup.Item key={day.date}>
                            <div className="day">{t(translatedDayOfWeekWithNumber(day.date))}</div>

                            <div className="interval-hours">
                              {day.intervals.length === 0 && (
                                <div>{t('medicalApp.unavailable')}</div>
                              )}
                              {day.intervals.map((interval) => {
                                return (
                                  <div
                                    key={`${interval.start} - ${interval.end}`}
                                  >{`${interval.start} - ${interval.end}`}</div>
                                );
                              })}
                            </div>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp10">
          {isLoading && !doctor.name ? (
            <div className="reviews">
              <div className="subtitle">
                <SkeletonComponent width="100" />
              </div>
              <div className="box">
                <div>
                  <SkeletonComponent width="full" />
                </div>
                <div>
                  <SkeletonComponent width="full" />
                </div>
                <div>
                  <SkeletonComponent width="full" />
                </div>
              </div>
            </div>
          ) : (
            <div className="reviews">
              <div className="subtitle">{t('reviews')}</div>
              <div className={`box ${doctor.reviews?.length === 0 ? 'no-reviews' : ''}`}>
                {doctor.reviews?.length === 0 && (
                  <>
                    <img
                      src={noReviewsImage}
                      alt="no reviews available"
                      style={{ marginBottom: '12px' }}
                    />
                    <div className="no-reviews-text">{t('medicalApp.noReviewsAtTheMoment')}</div>
                  </>
                )}
                {doctor.reviews?.map((review: any, index) => {
                  return (
                    <Fragment key={_.uniqueId()}>
                      {index < 3 && (
                        <div key={`${_.uniqueId()}_recent`} className="review">
                          <div className="header">
                            <div className="name">{review.originator}</div>
                            <div className="rating">
                              <CustomIcon
                                className="custom-icon"
                                color={Colors.mediumGrey}
                                size="14"
                                icon="Rating_2"
                              />
                              {t('note')} {review.rating} / 5
                            </div>
                          </div>

                          <div className="date">{review.createdAt}</div>

                          <div className="text">
                            {review.remarks.split(' ').length < 20
                              ? review.remarks
                              : `${review.remarks.split(' ').slice(0, 20).join(' ')} ...`}
                          </div>

                          {review.remarks.split(' ').length >= 20 && (
                            <button
                              type="button"
                              className="read-all"
                              onClick={() => {
                                modalReviewReadAllShow(review);
                              }}
                            >
                              {t('readAll')}
                            </button>
                          )}
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
              {doctor.reviews?.length > 0 && (
                <button type="button" className="view-all-reviews" onClick={modalAllReviewsShow}>
                  {t('seeAllReviews')}
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.patientColor}
                    size="24"
                    icon="Right_pointer"
                  />
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      <PatientMedicReviewsModal
        id={id}
        medic={doctor}
        modalVisibility={showModalAllReviews}
        hideModal={modalAllReviewsClose}
        modalReviewReadAllShow={modalReviewReadAllShow}
      />

      <Modal
        show={showModalReviewReadAll}
        onHide={modalReviewReadAllClose}
        animation
        centered
        className="modal-all-reviews"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('allReviews')}</Modal.Title>
        </Modal.Header>

        <Modal.Header className="subheader">
          <div className="medic-small-box">
            <div className="avatar" style={{ backgroundImage: `url(${doctor.pictureMedia})` }} />

            <div className="info">
              <div className="name">{doctor.name}</div>
              <div className="rating">
                <CustomIcon
                  className="custom-icon"
                  color={Colors.mediumGrey}
                  size="14"
                  icon="Rating_2"
                />
                {t('note')} {doctor.averageRating} ({doctor.countRating} {t('noOfReviews')})
              </div>
            </div>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="scroll-list">
            {modalReadMoreReview && (
              <div key={modalReadMoreReview.id} className="review">
                <div className="header">
                  <div className="name">{modalReadMoreReview.originator}</div>
                  <div className="rating">
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.mediumGrey}
                      size="14"
                      icon="Rating_2"
                    />
                    {t('note')} {modalReadMoreReview.rating} / 5
                  </div>
                </div>

                <div className="date">{modalReadMoreReview.createdAt}</div>

                <div className="text">{modalReadMoreReview.remarks}</div>
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="white-button"
            onClick={(event) => {
              modalAllReviewsShow();
              modalReviewReadAllClose();
            }}
          >
            {t('backToReviews')}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalViewProfilePicture}
        onHide={modalViewProfilePictureClose}
        animation
        centered
        backdropClassName="modal-view-profile-picture"
        className="modal-view-profile-picture"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{doctor.fullNameWithTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={doctor.pictureMedia} alt={doctor.fullNameWithTitle} className="img-fluid" />
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default PatientMedicProfile;
